import React, { useState, useEffect } from 'react';
import { PublicKey, Transaction, Connection } from '@solana/web3.js';
import { 
  TOKEN_PROGRAM_ID, 
  getAssociatedTokenAddress, 
  createBurnInstruction,
  getAccount,
  getMint,
  getTokenAccountsByOwner
} from '@solana/spl-token';
import { Buffer } from 'buffer';

// Polyfill Buffer
if (typeof window !== 'undefined') {
  window.Buffer = Buffer;
}

// Constants
const SOLANA_NETWORK = "https://mainnet.helius-rpc.com/?api-key=1fb263fa-6602-4cee-a7bb-83a22fbe4a52";

const App = () => {
  const [walletAddress, setWalletAddress] = useState('');
  const [provider, setProvider] = useState(null);
  const [connection, setConnection] = useState(null);
  const [tokens, setTokens] = useState([]);
  const [selectedToken, setSelectedToken] = useState(null);
  const [burnAmount, setBurnAmount] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [lastTxSignature, setLastTxSignature] = useState(null);

  useEffect(() => {
    const conn = new Connection(SOLANA_NETWORK, 'confirmed');
    setConnection(conn);

    const getProvider = () => {
      if ('phantom' in window) {
        const provider = window.phantom?.solana;
        if (provider?.isPhantom) {
          provider.connect({ onlyIfTrusted: true });
          setProvider(provider);
          return provider;
        }
      }
      window.open('https://phantom.app/', '_blank');
    };

    const provider = getProvider();
    if (provider) {
      provider.on('connect', (publicKey) => {
        setWalletAddress(publicKey.toString());
        setError(null);
        fetchTokenAccounts(publicKey);
      });
      provider.on('disconnect', () => {
        setWalletAddress('');
        setTokens([]);
        setSelectedToken(null);
      });
      if (provider.isConnected) {
        setWalletAddress(provider.publicKey.toString());
        fetchTokenAccounts(provider.publicKey);
      }
    }

    return () => {
      if (provider) {
        provider.disconnect();
      }
    };
  }, []);

  const fetchTokenAccounts = async (publicKey) => {
    try {
      setLoading(true);
      const tokenAccounts = await connection.getParsedTokenAccountsByOwner(
        publicKey,
        { programId: TOKEN_PROGRAM_ID }
      );

      const tokenDetails = await Promise.all(
        tokenAccounts.value.map(async (account) => {
          const mintAddress = account.account.data.parsed.info.mint;
          const mintInfo = await getMint(connection, new PublicKey(mintAddress));
          return {
            mint: mintAddress,
            balance: account.account.data.parsed.info.tokenAmount.uiAmount,
            decimals: mintInfo.decimals,
            tokenAccount: account.pubkey.toString()
          };
        })
      );

      setTokens(tokenDetails.filter(token => token.balance > 0));
    } catch (err) {
      console.error('Error fetching token accounts:', err);
      setError('Failed to fetch token accounts');
    } finally {
      setLoading(false);
    }
  };

  const connectWallet = async () => {
    try {
      const resp = await provider.connect();
      setWalletAddress(resp.publicKey.toString());
      await fetchTokenAccounts(resp.publicKey);
      setError(null);
    } catch (err) {
      setError('Failed to connect wallet: ' + err.message);
    }
  };

  const disconnectWallet = async () => {
    try {
      await provider.disconnect();
      setWalletAddress('');
      setTokens([]);
      setSelectedToken(null);
      setError(null);
    } catch (err) {
      setError('Failed to disconnect wallet: ' + err.message);
    }
  };

  const handleBurn = async () => {
    try {
      if (!provider?.isConnected) {
        throw new Error("Please connect your Phantom wallet first");
      }

      if (!selectedToken) {
        throw new Error("Please select a token to burn");
      }

      if (!burnAmount || parseFloat(burnAmount) <= 0) {
        throw new Error("Please enter a valid amount to burn");
      }

      const tokenInfo = tokens.find(t => t.mint === selectedToken);
      if (!tokenInfo) {
        throw new Error("Selected token not found");
      }

      if (parseFloat(burnAmount) > tokenInfo.balance) {
        throw new Error("Insufficient balance for burning");
      }

      setLoading(true);
      setError(null);
      setLastTxSignature(null);

      const tokenAccountPubkey = new PublicKey(tokenInfo.tokenAccount);
      const mintPubkey = new PublicKey(selectedToken);

      const burnInstruction = createBurnInstruction(
        tokenAccountPubkey,
        mintPubkey,
        provider.publicKey,
        parseInt(parseFloat(burnAmount) * (10 ** tokenInfo.decimals))
      );

      const transaction = new Transaction().add(burnInstruction);
      const latestBlockhash = await connection.getLatestBlockhash('confirmed');
      transaction.recentBlockhash = latestBlockhash.blockhash;
      transaction.feePayer = provider.publicKey;

      const signed = await provider.signAndSendTransaction(transaction);
      await connection.confirmTransaction({
        signature: signed.signature,
        blockhash: latestBlockhash.blockhash,
        lastValidBlockHeight: latestBlockhash.lastValidBlockHeight
      });

      setLastTxSignature(signed.signature);
      await fetchTokenAccounts(provider.publicKey);
      setBurnAmount('');

    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const formatWalletAddress = (address) => {
    if (!address) return '';
    return `${address.slice(0, 4)}...${address.slice(-4)}`;
  };

  return (
    <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
      <div className="relative py-3 sm:max-w-xl sm:mx-auto">
        <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
          <div className="flex flex-col items-center justify-center space-y-6">
            <div className="w-full flex justify-center mb-4">
              {!walletAddress ? (
                <button
                  onClick={connectWallet}
                  className="bg-purple-500 hover:bg-purple-600 text-white font-bold py-2 px-4 rounded"
                >
                  Connect Phantom Wallet
                </button>
              ) : (
                <div className="flex flex-col items-center space-y-2">
                  <div className="flex items-center space-x-4">
                    <span className="bg-gray-100 rounded-full px-4 py-2 text-sm font-medium">
                      {formatWalletAddress(walletAddress)}
                    </span>
                    <button
                      onClick={disconnectWallet}
                      className="bg-red-500 hover:bg-red-600 text-white text-sm py-2 px-4 rounded"
                    >
                      Disconnect
                    </button>
                  </div>
                </div>
              )}
            </div>

            {loading && (
              <div className="text-gray-600">Loading...</div>
            )}

            {walletAddress && !loading && (
              <div className="w-full space-y-4">
                <select
                  value={selectedToken || ''}
                  onChange={(e) => setSelectedToken(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md"
                >
                  <option value="">Select a token</option>
                  {tokens.map((token) => (
                    <option key={token.mint} value={token.mint}>
                      {formatWalletAddress(token.mint)} - Balance: {token.balance}
                    </option>
                  ))}
                </select>

                <input
                  type="number"
                  value={burnAmount}
                  onChange={(e) => setBurnAmount(e.target.value)}
                  placeholder="Amount to burn"
                  className="w-full p-2 border border-gray-300 rounded-md"
                  min="0"
                  step="any"
                />

                <button
                  onClick={handleBurn}
                  disabled={loading || !selectedToken || !burnAmount}
                  className={`w-full py-2 px-4 rounded-md text-white font-medium
                    ${loading || !selectedToken || !burnAmount
                      ? 'bg-gray-400 cursor-not-allowed'
                      : 'bg-red-500 hover:bg-red-600 active:bg-red-700'
                    }`}
                >
                  {loading ? 'Processing...' : 'Burn Tokens'}
                </button>
              </div>
            )}

            {error && (
              <div className="text-red-500 text-sm mt-2">
                {error}
              </div>
            )}

            {lastTxSignature && (
              <div className="text-sm text-green-600 text-center mt-2">
                Tokens burned successfully! Transaction:
                <a 
                  href={`https://solscan.io/tx/${lastTxSignature}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:text-blue-700 ml-1"
                >
                  {formatWalletAddress(lastTxSignature)}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;